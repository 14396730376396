import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const MichaelDetora = () => {
  return (
    <Layout>
      <Seo title="Michael DeTora, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br />
                Dr. Michael DeTora
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container  mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/detora_250.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Michael DeTora is a native of Auburn, Massachusetts and the
                son of a veterinarian. He attended Virginia Tech and played
                soccer for the Hokies. His DVM was completed at Tufts Cummings
                School of Veterinary Medicine. Following graduation, a 1-year
                small animal rotating internship was completed at North Carolina
                State University. Dr. DeTora then returned to Tufts to complete
                a 3-year residency in small animal surgery. He became board
                certified by the American College of Veterinary Surgeons in
                2012.
              </p>
              <p>
                Michael has a passion for minimally invasive surgeries, cranial
                cruciate ligament injury, regenerative medicine, and total joint
                arthroplasty. He has spent time practicing in Florida, Maryland,
                and Massachusetts. In his spare time, he enjoys soccer,
                basketball, fishing, golf, winter sports, running with his wife
                and her athletic dog, Jehger and wrestling with his bulldog,
                Albus. He especially enjoys time with his daughter, Ava. Dr.
                DeTora (and his wife, Dr. Andra DeTora) have enjoyed working
                with the CCO family since 2016
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/489028503"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default MichaelDetora
